import React from 'react';

function Footer() {
  return(
    <footer className="footer">
      site created with love by bp.
    </footer>
  )
}

export default Footer
