import React from 'react';
// import breckyfam from '../media/pwfamily.JPG';
import NavBar from './NavBar';

class Home extends React.Component {

  render() {
    return(
      <div className='main'>
        <NavBar />
        <h1>hello there.</h1>
      </div>
    )
  }
}

export default Home
